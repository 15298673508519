
.site-footer
    background: $brand-green
    color: white
    padding: 3rem 0 2rem

.site-footer a
    color: $brand-green-light

    @include hover-focus
        color: darken($brand-green-light, 15%)

.site-footer-contact
    margin-bottom: 2rem
    line-height: 1.75

.site-footer-contact-name
    font-size: 1.5rem
    line-height: 1.25
    font-weight: 500
    margin: 1.25rem 0 0

.site-footer-copyright
    font-weight: 700
    margin: 0

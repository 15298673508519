
.download
    background-color: $brand-blue
    border-radius: 20px
    color: white
    display: inline-block
    font-size: 1.25rem
    font-weight: 700
    padding: 1rem
    text-align: center
    text-decoration: none
    text-transform: uppercase

    @include hover-focus
        background-color: darken($brand-blue, 15%)
        color: white
        text-decoration: none

.download-pdf
    background-image: url('../images/icon-pdf.png')
    background-position: left 1rem center
    background-repeat: no-repeat
    padding-left: 107px
    padding-left: calc(2rem + 75px)

.alignnone,
.alignleft,
.aligncenter,
.alignright,
a img.alignnone,
a img.alignleft,
div.aligncenter,
a img.alignright,
.wp-caption.alignnone,
.wp-caption.alignleft,
.wp-caption.alignright
    @extend .img-fluid
    margin: 0.3125rem 0 1.25rem

.aligncenter,
div.aligncenter,
a img.aligncenter
    display: block
    margin-left: auto
    margin-right: auto

@include media-breakpoint-up(md)
    .alignnone,
    .alignleft,
    a img.alignnone,
    a img.alignleft,
    .wp-caption.alignnone,
    .wp-caption.alignleft
        margin-right: 1.25rem

    .alignright,
    a img.alignright,
    .wp-caption.alignright
        margin-left: 1.25rem

    .alignleft,
    a img.alignleft
        clear: left
        float: left
        max-width: 50%

    .alignright,
    a img.alignright
        clear: right
        float: right
        max-width: 50%

.wp-caption
    background: #fff
    border: 1px solid #f0f0f0
    max-width: 96%
    /* Image does not overflow the content area */
    padding: 0.3125rem 0.1875rem 0.625rem
    text-align: center

.wp-caption img
    border: 0 none
    height: auto
    margin: 0
    max-width: 98.5%
    padding: 0
    width: auto

.wp-caption p.wp-caption-text
    font-size: 0.875rem
    margin: 0
    padding: 0.3125rem 0.25rem 0

/* Text meant only for screen readers. */
.screen-reader-text
    clip: rect(1px, 1px, 1px, 1px)
    position: absolute !important
    height: 1px
    width: 1px
    overflow: hidden

.screen-reader-text:focus
    background-color: #f1f1f1
    border-radius: 0.1875rem
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6)
    clip: auto !important
    color: #21759b
    display: block
    font-size: 0.875rem
    font-weight: bold
    height: auto
    left: 0.3125rem
    line-height: normal
    padding: 0.9375rem 1.4375rem 0.875rem
    text-decoration: none
    top: 0.3125rem
    width: auto
    z-index: 100000

//
// Gallery
//

.gallery
    @extend .row

.gallery-item
    @extend .col-xs-12
    margin-bottom: 1.25rem

.gallery-columns-2 .gallery-item
    @include media-breakpoint-up(xs)
        @include make-col-span(1, 2)

.gallery-columns-3 .gallery-item
    @include media-breakpoint-up(xs)
        @include make-col-span(1, 2)
    @include media-breakpoint-up(sm)
        @include make-col-span(1, 3)

.gallery-columns-4 .gallery-item
    @include media-breakpoint-up(xs)
        @include make-col-span(1, 2)
    @include media-breakpoint-up(sm)
        @include make-col-span(1, 4)

.gallery-columns-5 .gallery-item
    @include media-breakpoint-up(xs)
        @include make-col-span(1, 2)
    @include media-breakpoint-up(sm)
        @include make-col-span(1, 3)
    @include media-breakpoint-up(md)
        @include make-col-span(1, 5)

.gallery-columns-6 .gallery-item
    @include media-breakpoint-up(xs)
        @include make-col-span(1, 2)
    @include media-breakpoint-up(sm)
        @include make-col-span(1, 3)
    @include media-breakpoint-up(md)
        @include make-col-span(1, 4)
    @include media-breakpoint-up(lg)
        @include make-col-span(1, 6)

.gallery-columns-7 .gallery-item
    @include media-breakpoint-up(xs)
        @include make-col-span(1, 2)
    @include media-breakpoint-up(sm)
        @include make-col-span(1, 3)
    @include media-breakpoint-up(md)
        @include make-col-span(1, 4)
    @include media-breakpoint-up(lg)
        @include make-col-span(1, 7)

.gallery-columns-8 .gallery-item
    @include media-breakpoint-up(xs)
        @include make-col-span(1, 2)
    @include media-breakpoint-up(sm)
        @include make-col-span(1, 3)
    @include media-breakpoint-up(md)
        @include make-col-span(1, 4)
    @include media-breakpoint-up(lg)
        @include make-col-span(1, 8)

.gallery-columns-9 .gallery-item
    @include media-breakpoint-up(xs)
        @include make-col-span(1, 2)
    @include media-breakpoint-up(sm)
        @include make-col-span(1, 3)
    @include media-breakpoint-up(md)
        @include make-col-span(1, 4)
    @include media-breakpoint-up(lg)
        @include make-col-span(1, 9)

.gallery-icon img
	margin: 0 auto

.gallery-columns-6 .gallery-caption,
.gallery-columns-7 .gallery-caption,
.gallery-columns-8 .gallery-caption,
.gallery-columns-9 .gallery-caption
	display: none

.attachment-full
    @extend .img-fluid

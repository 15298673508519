
.pager
    @include clearfix()
    list-style: none
    margin: 2.5rem 0 0
    padding-left: 0
    text-align: center

.pager li
    display: inline

.pager li > a
    display: inline-block

.pager-next > a
    float: right

.pager-prev > a
    float: left

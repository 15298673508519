
.site-header
    @include clearfix
    padding-top: 0.75rem
    position: relative
    z-index: 1

.site-header-phone
    color: #1d1d1b
    font-size: $font-size-lg
    font-weight: bold

    @include media-breakpoint-down(sm)
        padding-bottom: 0.5rem
        text-align: center

    @include media-breakpoint-up(md)
        float: right

    @include media-breakpoint-up(lg)
        margin-top: 1.25rem

.site-header-logo
    float: left
    margin-bottom: -0.75rem
    width: 180px
    position: relative
    z-index: 2

    @include media-breakpoint-up(md)
        margin-bottom: -1.25rem
        width: 200px

    @include media-breakpoint-up(lg)
        margin-bottom: -2.25rem
        width: 300px

.site-header-nav-toggler
    //background: $gray-lighter
    background: $brand-blue
    color: white
    border: $border-width solid transparent
    @include border-radius($btn-border-radius)
    float: right
    font-size: 1.25rem
    line-height: 1
    margin-top: 28px
    padding: 0.5rem 0.75rem

    @include hover-focus
        background: darken($brand-blue, 15%)
        text-decoration: none

    @include media-breakpoint-up(md)
        display: none

.site-header-nav
    position: relative
    z-index: 1

    @include media-breakpoint-down(sm)
        background: $gray-lighter
        clear: both
        margin-left: -($grid-gutter-width / 2)
        margin-right: -($grid-gutter-width / 2)
        padding: 0.375rem 0 0

    @include media-breakpoint-up(md)
        clear: right
        display: block
        float: right
        margin: 0.5rem -0.75rem 0

    @include media-breakpoint-up(lg)
        margin: 1.5rem -1.5rem 0

.site-header-nav a
    font-size: 1.25rem
    font-weight: 500

    @include hover-focus
        text-decoration: none

    @include media-breakpoint-down(sm)
        display: block
        padding: 0.325rem ($grid-gutter-width / 2)

        & + a
            border-top: 1px solid darken($gray-lighter, 10%)

    @include media-breakpoint-up(md)
        margin: 0 0.75rem

    @include media-breakpoint-up(lg)
        margin: 0 1.5rem

html
    min-height: 100%
    letter-spacing: 0.42px
    overflow-y: scroll
    position: relative
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)

ul, ol
    padding-left: 1.25rem


table
    border: 0
    border-collapse: collapse
    border-spacing: 0
    margin-bottom: 1.25rem
    width: 100%
    max-width: 100%

    th, td
        border: 1px solid $table-border-color
        line-height: $line-height
        padding: $table-cell-padding
        vertical-align: top

    thead th
        vertical-align: bottom
        border-bottom: 2px solid $table-border-color

    tbody + tbody
        border-top: 2px solid $table-border-color

    table
        background-color: $body-bg

@include media-breakpoint-down(md)
    .table-wrapper
        display: block
        overflow-x: auto
        white-space: nowrap
        width: 100%

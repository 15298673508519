.browserupgrade
    background: $brand-danger
    color: white
    margin: 0
    padding: 0.5rem 0
    text-align: center

.browserupgrade a
    color: white
    text-decoration: underline

// Colors
//
// Grayscale and brand colors for use across Bootstrap.

//$gray-dark: #373a3c
$gray: #575756
$gray-light: #6f6f6e
$gray-lighter: #ececec
//$gray-lightest: #f7f7f9

$brand-blue: #204999
$brand-green: #09612d
$brand-green-light: #94b445

//$brand-primary: #0275d8
//$brand-success: #5cb85c
//$brand-info: #5bc0de
//$brand-warning: #f0ad4e
//$brand-danger: #d9534f

// Body
//
// Settings for the `<body>` element.

$body-bg: #fff
$body-color: $gray

// Links
//
// Style anchor elements.

$link-color: $brand-blue
$link-decoration: none
$link-hover-color: darken($link-color, 15%)
$link-hover-decoration: underline

// Grid breakpoints
//
// Define the minimum and maximum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (xs: 0, sm: 544px, md: 768px, lg: 992px, xl: 1080px)

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (sm: 576px, md: 720px, lg: 940px, xl: 1028px)

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-size-root: 14px

$font-size-h1: 2rem
$font-size-h2: 1.5rem
$font-size-h3: 1.25rem
$font-size-h4: 1.125rem
$font-size-h5: 1.125rem
$font-size-h6: 1rem

$headings-margin-bottom: 1.25rem
$headings-font-weight: 500

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding: 0.3rem 0.5rem


.panel
    padding: 1.5rem 0

    @include media-breakpoint-up(md)
        padding: 3rem 0

.panel p:last-child,
.panel ul:last-child,
.panel ol:last-child
    margin-bottom: 0

.panel-shade
    background: $gray-lighter

.panel-heading
    color: $gray-light
    font-size: 1.75rem
    margin-bottom: 2.5rem

.panel-action
    font-size: 1.25rem
    margin-top: 0.6rem

    @include media-breakpoint-up(md)
        margin-top: 0.5rem

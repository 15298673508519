
.article
    //

.article + .article
    margin-top: 3rem

.article-heading
    font-size: 1.5rem
    font-weight: 500
    line-height: 1.25
    margin-top: 0.625rem
    margin-bottom: 0.3125rem

    @include media-breakpoint-up(sm)
        margin-top: -0.3125rem

.article-heading a
    color: $body-color

.article-meta
    display: block
    margin-bottom: 0.3125rem

.article-excerpt
    //

.article-more
    //

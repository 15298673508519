
.page-banner
    background-color: $gray-lighter
    background-position: center center
    background-repeat: no-repeat
    background-size: cover
    border-bottom: 8px solid $brand-green
    height: 248px
    position: relative

    @include media-breakpoint-up(md)
        height: 420px
        border-bottom-width: 25px

.page-header
    background: rgba($brand-green, 0.44)
    bottom: 0
    position: absolute
    padding: 1rem 0
    width: 100%

    @include media-breakpoint-up(md)
        padding: 2rem 0

.page-header h1
    color: white
    font-size: 1.75rem
    margin-bottom: 0
    text-shadow: 4px 3px 5px rgba(#1d1d1b, 0.75)

    @include media-breakpoint-up(md)
        font-size: 2rem
